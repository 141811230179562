<template>
    <Promote :studentId="12"/>
</template>
<script>
    import Promote from './promote'

    export default {
        components: {
            Promote
        }
    }
</script>